/* src/index.css */
@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600;700;800&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lora:400,700&family=Merriweather&family=Nunito&family=Open+Sans&family=Sono&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


html {
    font-size: 16px !important;
  }